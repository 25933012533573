import {useContext, useEffect, useState} from "react";
import SavedOutputsContext from "../store/saved-outputs";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { DocumentDuplicateIcon, HeartIcon } from "@heroicons/react/24/outline";
import { CheckIcon, HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import LoadingModal from "./ui/LoadingModal";
import Modal from "./ui/Modal";
import TOOLS from "../data/tools";
import {imageHttp, modelHttp, saveHttp, statsHttp} from "../utils/http";
import { info, success, warning, error } from "../notify/Notify";
import {AppDataContext} from "../data/AppHelpers";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OutputItem(props) {
  const { toolId } = useParams();
  const navigate = useNavigate();
  const [currentTool, setCurrentTool] = useState({});
  const [modal, setModal] = useState(null);

  const savedOutputsCtx = useContext(SavedOutputsContext);
  const { appDataContext } = useContext(AppDataContext);
  const activeTab = savedOutputsCtx.activeTab;

  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isSaved, setIsSaved] = useState(props.isSaved);
    const [imageUrls, setImageUrls] = useState([]);
  const [currentBloggerContent, setCurrentBloggerContent] = useState(props.content);
  console.log('currentBloggerContent', currentBloggerContent);


  useEffect(() => {
    const tool = TOOLS.find(tool => tool.id === toolId);
    if (tool) {
      setCurrentTool(tool);
    }
    }, [toolId]);
  // console.log('currentTool Outputitem', currentTool);


  const shouldShowPlagiarismButton =
      currentTool.id !== "blog-ideas" &&
      currentTool.id !== "blog-titles";

  // console.log("Current Tool ID:", currentTool.id);
  // console.log("Should Show Plagiarism Button:", shouldShowPlagiarismButton);

  useEffect(() => {
    async function fetchImages() {
      if (props.type !== "image-generator") return;

      try {
        const results = await imageHttp(appDataContext.user.meta.cf_key, {
          description: props.description,
          style: props.style,
          medium: props.medium,
          mood: props.mood,
          details: props.details
        });

        if (results.length > 0) {
          setImageUrls(results.map(result => result.url));
        } else {
          throw new Error('No image URLs returned');
        }
      } catch (error) {
        console.error('Error fetching images:', error);
        setModal({
          title: "Error",
          message: "Error fetching images",
          type: "error",
        });
      }
    }

    fetchImages();
  }, [props.type, props.description, props.style, props.medium, props.mood, props.details, appDataContext.user.meta.cf_key]);



  async function toggleCopiedStatusHandler() {
    if (!isCopied) {
      setIsCopied(true);
      setIsCopied(false);
    }
  }

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      await delay(2000);
      setIsCopied(false);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  function countWords(value) {
    const regex = /\s+/gi;
    let wordCount = value.trim().replace(regex, " ").split(" ").length;
    return wordCount;
  }

  async function checkPlagiarismHandler(content, cfKey) {
    setIsLoading(true);

    let wordCounts = currentBloggerContent !== "" ? countWords(currentBloggerContent) : 0;
    console.log("Word count:", wordCounts);

    if (wordCounts > 15) {
      try {
        const response = await props.eventHandlers.plagiarismHandler(content, cfKey);

        setIsLoading(false);

        if (response.data) {
          setModal({
            title: "Plagiarism Score",
            message: "Your score is " + response.data.rate + "%",
          });
          console.log(response.data.rate);
          console.log(response.data.results);
        } else {
          setModal({
            title: "Plagiarism Score",
            message: "Error checking plagiarism",
            type: "error",
          });
        }
      } catch (error) {
        setIsLoading(false);
        setModal({
          title: "Plagiarism Score",
          message: "Error checking plagiarism",
          type: "error",
        });
        console.error('Plagiarism check error:', error);
      }
    } else {
      setIsLoading(false);
      setModal({
        title: "Error",
        message: "Not enough words to check plagiarism",
        type: "error",
      });
    }
  }


  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const modalHandler = () => {
    setModal(null);
  };

  return (
    <>
      {isLoading && <LoadingModal title="Checking Plagiarism" content="Please wait, this can take a few seconds." onClose={modalHandler} />}
      {modal && <Modal onClose={modalHandler} title={modal.title} type={modal.type} content={modal.message} buttonLabel="OK" />}
      <article className={classNames(props.isNew ? "opacity-100" : "opacity-50", props.isSelected && !props.isSaved && "border-1 border-primary-600", props.isSelected && props.isSaved && "border-1 border-primary-800", props.isSaved && !props.isSelected && "border-green-500", "output-item relative group hover:opacity-100 duration-300 leading-relaxed font-bold text-gray-700 bg-white p-7 rounded-md border border-gray-200 mb-4")}>
        {props.content.url ? (
            <div className="image-container">
              <img src={props.content.url} alt="Generated image" />
            </div>
        ) : (
            <div className="mb-2" dangerouslySetInnerHTML={{ __html: props.content }} />
        )}
        <div className="text-right absolute bottom-0 right-0 p-4 opacity-0 duration-200 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 flex space-x-3">
          {shouldShowPlagiarismButton && (
          <button
              onClick={() => checkPlagiarismHandler(currentBloggerContent)}
              type="button"
              className="inline-flex shadow-sm active:scale-[0.98] duration-100 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50">
            <MagnifyingGlassIcon className="h-3 w-3 mr-1" />
            <span>Check&nbsp;Plagiarism</span>
          </button>
            )}
          <button onClick={() => handleCopy(props.content)} type="button" className="inline-flex shadow-sm active:scale-[0.98] duration-100 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50">
            {isCopied ? (
              <>
                <CheckIcon className="h-3 w-3 mr-1" />
                <span>Copied!</span>
              </>
            ) : (
              <>
                <DocumentDuplicateIcon className="h-3 w-3 mr-1" />
                <span>Copy</span>
              </>
            )}
          </button>
          <button onClick={props.eventHandlers.saveHandler} type="button" className="inline-flex shadow-sm active:scale-[0.98] duration-100 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 disabled:hover:bg-white disabled:pointer-events-none disabled:opicty:75" disabled={props.isSaved}>
            {props.isSaved ? (
              <>
                <CheckIcon className="h-3 w-3 mr-1 text-green-500" />
                <span id={props.id}>Saved</span>
              </>
            ) : (
              <>
                <HeartIcon id={props.id} className="h-3 w-3 mr-1" />
                <span id={props.id}>Save</span>
              </>
            )}
          </button>
        </div>
        <div></div>
      </article>
    </>
  );
}
