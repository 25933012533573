import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OutputPanel from "../../Partials/OutputPanel";
import Button from "../ui/Button";
import Input from "../ui/Input";
import Textarea from "../ui/TextArea";
import TOOLS from "../../data/tools";
import FormDropdown from "../ui/FormDropdown";
import QuantityInput from "../ui/QuantityInput";
import ToolSwitchCommandPanel from "../ToolSwitchCommandPanel";
import Tippy from "@tippyjs/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import ToolIcon from "../../Partials/ToolIcon";
import Modal from "../../Components/ui/Modal";
import DragDropList from "../ui/DragDropList";
import DynamicDropdownList from "../ui/DynamicDropdownList";
import MyTagsInput from "../ui/TagsInput";
import { InformationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import VideoModal from "../ui/VideoModal";
import IconDropdown from "../ui/IconDropdown";
import axios from "axios";
import { AppDataContext } from "../../data/AppHelpers";
import { config } from "./../../config";
import { generateUUID } from "./../../utils/uuid";
import { buildRequest, plagarismHttp, modelHttp } from "./../../utils/http";
import { useCreditsContext } from "../../data/CreditsContext";
import { useLocation } from "react-router-dom";
import { warning } from "../../notify/Notify";
import HeaderBanner from "../../Partials/HeaderBanner";
import ArticleRewriterPage from "./../../Pages/ArticleRewriter/ArticleRewriter";
import EditContent from "./../../Pages/EditContent/EditContent";
import TextArea from "../TextArea";
import OutputList from "../OutputList";
import OutputItem from "../OutputItem";

function Factory(props) {
  switch (props.component.type) {
    case "input":
      return <Input onChange={props.onChange} value={props.value} {...props.component} />;
    case "textarea":
      return <Textarea onChange={props.onChange} value={props.value} {...props.component} />;
    case "dropdown":
      return <FormDropdown onChange={props.onChange} {...props.component} />;
    case "iconDropdown":
      return <IconDropdown onChange={props.onChange} values={props.component.values} label={props.component.label} />;
    case "tagsInput":
      return <MyTagsInput onChange={props.onChange} required={props.component.required} {...props.component} />;
    case "dynamicDropdownList":
      return <DynamicDropdownList onChange={props.onChange} required={props.component.required} {...props.component} />;
    case "dynamicInputList":
      return <DragDropList onChange={props.onChange} required={props.component.required} {...props.component} items={[{ id: "0", value: "" }]} />;
    default:
      return null;
  }
}

const defaultOutputPanelSubtitle = "Not sure how this works? Get an example by clicking the button below.";

export default function ToolFactory(props) {
  const { toolId } = useParams();
  const navigate = useNavigate();
  const [currentTool, setCurrentTool] = useState(TOOLS.find(tool => tool.id === toolId));
  const singleColLayout = currentTool.layout ?? "single";
  const quantity = currentTool.defaultQuantity ? currentTool.defaultQuantity : 4;
  const [formFields, setFormFields] = useState(buildState() || []);
  const [isLoading, setIsLoading] = useState(false);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [outputList, setOutputList] = useState([]);
  const [formError, setFormError] = useState(null);
  const [modal, setModal] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const { appDataContext, updateRecentTools, paidUserStatus } = useContext(AppDataContext);
  const { setCredits } = useCreditsContext();

  const sessionId = generateUUID();
  console.log('currentTool', currentTool);


  const location = useLocation();

  useEffect(() => {
    const newTool = toolId ? TOOLS.find((tool) => tool.id === toolId) : TOOLS[7];
    setCurrentTool(newTool);
    setFormFields(buildState());
  }, [location, toolId]);

  useEffect(() => {
    if (appDataContext.isDependencyLoaded) {
      const body = document.body;
      const html = document.getElementById("root");
      const screenHeight = Math.max(html.clientHeight);

      /* global SM */
      SM.client("resizeWindow", { height: screenHeight });
    }
  });

  function buildState() {

    let formfieldsArray = [];
    currentTool.formFields.forEach((formField) => {
      // needs icon dropdown
      const defaultValue = formField.type === "dropdown" ? formField.values[0].name : null;
      formfieldsArray.push({ field: formField, value: defaultValue });
    });
    formfieldsArray.push({ field: { name: "n" }, value: quantity }); // this is probably a bug factory
    return formfieldsArray;
  }

  function switchToolHandler(newToolId) {
    const newTool = newToolId ? TOOLS.find((tool) => tool.id === newToolId) : TOOLS[7];
    setCurrentTool(newTool);
    setFormFields(buildState());
    setPanelOpen(false);
    setOutputList([]);
  }

  const openVideoModal = () => {
    setShowVideoModal(true);
  };

  const eventHandlers = {
    plagiarismHandler: plagiarismHandler,
    saveHandler: saveHandler,
    copyTextHandler: copyTextHandler,
    copyImageHandler: copyImageHandler,
  };

  // sent req to 3rd party service
  async function plagiarismHandler(content) {
    const url = config(appDataContext.ENV).copymatic_api;
    const cfKey = appDataContext.user.meta.cf_key;
    return plagarismHttp(appDataContext.jwt, cfKey, content, url);
  }

  // save the data and update the output Array's index for the object
  async function saveHandler(event) {

    const outputItem = outputList.find((item) => item.id === parseInt(event.target.id));
    const outputType = currentTool.outputType;

    if (outputItem) {
      const request = {
        model: currentTool.id,
        jwt: appDataContext.jwt,
        cf_key: appDataContext.user.meta.cf_key,
        user_id: appDataContext.user.id,
        project_id: "",
        type: currentTool.outputType !== "images" ? "post" : "images",
        env: "copymatic",
      };

      // TODO what value needs to be a title
      if (currentTool.outputType !== "images") {
        request.content = outputItem.content;
        request.title = "";
        request.wordcount = outputItem.content.length;
      } else {
        request.image_name = outputItem.content.url;
      }

      const url = config(appDataContext.ENV).copymatic_api + "save";

      return axios.post(url, request).then((response) => {
        const itemId = event.target.id;
        setOutputList((ol) => {
          return ol.map((i) => {
            if (i.id == itemId) {
              i.isSaved = true;
            }
            return i;
          });
        });
        return response;
      });
    } else {
      warning("Can't save item", true);
    }
  }

  async function formSubmitHandler(event) {

    event.preventDefault();
    setIsLoading(true);

    const [request, errors] = buildRequest(appDataContext, formFields, currentTool);

    if (errors.length > 0) {
      setIsLoading(false);
      let errormessage = errors[0];
      if(errormessage === 'no_enough_credits') {
        errormessage = paidUserStatus ? "You have exceeded your fair use limit for this month." : "You don't have enough words left to generate more content.";
      } else if(errormessage === 'error-occurred' || errormessage === 'SyntaxError: Unexpected end of JSON input') {
        errormessage = "An error occurred while processing your request. Please try again shortly";
      } else if(errormessage === 'fair_use_exceeded' || errormessage === 'fair-use-exceeded') {
        errormessage = "You have exceeded your fair use limit for this month.";
      } else if(errormessage === 'service_saturated') {
        errormessage = "An error occurred while processing your request. Please try again shortly";
      } else if(errormessage === 'sensitive_content') {
        errormessage = "We don't allow any political, religious, sexual or legal content.";
      } else if(errormessage === 'amazon_product_error') {
        errormessage = "We were not able to fetch the details of this product, please try again or try with another product.";
      } else if(errormessage === 'invalid_amazon_url') {
        errormessage = "It looks like the URL you entered is not a valid Amazon product URL as we were not able to determine the ASIN code.";
      } else if(errormessage === 'website_content_error') {
        errormessage = "We couldn't fetch the content of this URL, please try again or try with another URL.";
      } else if(errormessage === 'low-credits') {
        errormessage = paidUserStatus ? "You are approaching your fair use limit for this month." : "Your remaining words are running low. Consider upgrading your account to keep generating content.";
      }
      setFormError({ message: errormessage, title: "Error", type: "error" });
      return;
    }
    updateRecentTools([currentTool.id]);

    const response = await modelHttp(request, currentTool.id === "article-generator");

    if (response && response.results) {
      console.log('API response', response);
      setCredits(response.balance);

      const outputFetchedData = [];

      if (currentTool.id === "article-generator") {
        let index = 1;
        for (const item of response.results) {
          if (item.type === 'subheading') {continue;}
          if (item.type === 'intro') {
            outputFetchedData.push({ id: index, isNew: true, isSaved: false, content: item.content, type: item.type });
          } else {
            outputFetchedData.push({ id: index, isNew: true, isSaved: false, content: item.content });
          }
          index++;
        }
      } else {
        for (const key in response.results) {
          outputFetchedData.push({ id: key, isNew: true, isSaved: false, content: response.results[key] });
        }
      }

      const oldOutputs = outputList;
      oldOutputs.forEach((item) => (item.isNew = false));
      const output = [...outputFetchedData.concat(oldOutputs)].map((o, i) => {
        console.log('o', o);
        return { ...o, id: i };
      });
      setOutputList(output);
    } else {

      let message;
      let title = "Error";
      let type = "error";

      if (response.error === 'not_enough_credits' || response.error === 'no_enough_credits') {
        message = paidUserStatus ? <></> : <HeaderBanner appData={appDataContext} displayInModal={true}/>;
        title = paidUserStatus ? "Monthly fair use limit exceeded" : "Insufficient word credits";
      } else if(response.error === 'error-occurred' || response.error === 'SyntaxError: Unexpected end of JSON input') {
        message = "An error occurred while processing your request. Please try again shortly";
      } else if(response.error === 'fair_use_exceeded' || response.error === 'fair-use-exceeded') {
        message = "You have exceeded your fair use limit for this month.";
      } else if(response.error === 'service_saturated') {
        message = "An error occurred while processing your request. Please try again shortly";
      } else if(response.error === 'sensitive_content') {
        message = "We don't allow any political, religious, sexual or legal content.";
      } else if(response.error === 'amazon_product_error') {
        message = "We were not able to fetch the details of this product, please try again or try with another product.";
      } else if(response.error === 'invalid_amazon_url') {
        message = "It looks like the URL you entered is not a valid Amazon product URL as we were not able to determine the ASIN code.";
      } else if(response.error === 'website_content_error') {
        message = "We couldn't fetch the content of this URL, please try again or try with another URL.";
      } else if(response.error === 'low-credits') {
        message = paidUserStatus ? "You are approaching your fair use limit for this month." : "Your word credits are running low. Consider upgrading your account to keep generating content.";
      } else {
        message = "An unknown error occurred. Please try again.";
      }
      setFormError({ message: message, title: title, type: type });
    }

    setIsLoading(false);
  }

  function handleChange(formField, event) {
    let data;
    switch (formField.field.type) {
      case "input":
      case "number":
      case "textarea":
        data = event.target.value;
        break;
      case "n":
      case "iconDropdown":
      case "tagsInput":
      case "dynamicDropdownList":
      case "dropdown":
      case "dynamicInputList":
        data = event;
        break;
    }

    const updatedFormfields = formFields.map((field) => {
      if (field.field.name === formField.field.name) {
        field.value = data;
      }
      return field;
    });
    setFormFields(updatedFormfields);
  }

  function copyTextHandler(data) {
    navigator.clipboard.writeText(data);
  }

  // FIXME
  async function copyImageHandler(data) {
    try {
      await navigator.clipboard.writeText(data);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  const writeExampleHandler = () => {

    let updatedFormfields = [...formFields];

    // Clear all the fields that will be overwritten
    updatedFormfields.forEach((formEntry) => {
      if (formEntry.field.defaultValue) {
        formEntry.value = formEntry.field.defaultValue;
      }
    });

    setFormFields(updatedFormfields);
  };

  return (
    <>
      {currentTool && (
        <>
          {formError && (
            <Modal
              onClose={() => {
                setFormError(null);
              }}
              title={formError.title}
              type={formError.type}
              content={formError.message}
              buttonLabel="OK"
            />
          )}

          {/* Popup to select another tool */}
          <ToolSwitchCommandPanel isOpen={isPanelOpen} onClose={() => setPanelOpen(false)} onToolSelect={switchToolHandler} />

          <main className="min-h-full">
            {modal && (
              <Modal
                onClose={() => {
                  setModal(null);
                }}
                title={modal.title}
                content={modal.message}
                type={modal.type ? modal.type : "error"}
                buttonLabel="OK"
              />
            )}
            {/* If: Single column layout - Page title + Long description*/}
            {singleColLayout &&
              <>
                {((currentTool.id === 'article-generator' && outputList.length === 0) || (currentTool.id !== 'article-generator')) &&
                  <section className="mx-auto max-w-7xl pb-8 px-4 sm:py-4 sm:pb-8 sm:px-6 lg:px-8" >
                    <div className="text-center">
                      <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl lg:text-4xl">{currentTool.title}</h1>
                      <p className="mx-auto mt-5 max-w-xl font-medium text-base text-gray-600">{currentTool.longDescription ? currentTool.longDescription : currentTool.description}</p>
                    </div>
                  </section>
                }

                <div className="mx-auto w-full justify-center flex flex-wrap xl:flex-nowrap max-w-3xl gap-6 lg:max-w-7xl">

                  {outputList.length === 0 &&
                    <div className="w-full max-w-2xl mb-40 bg-white shadow sm:rounded-lg dark:bg-slate-900">
                      {/* START: Current Tool Form ------------------ */}
                      <div className="border-t dark:border-slate-700 px-4 pt-5 mb-8 space-y-8 flow-root">
                        <form className="grid grid-cols-2 gap-x-4 gap-y-5" onSubmit={formSubmitHandler}>
                          {(currentTool.topMessage && !paidUserStatus) ? (
                            <div className="rounded-md col-span-2 bg-primary-50 p-4">
                              <div className="flex">
                                <div className="flex-shrink-0">
                                  <InformationCircleIcon className="h-5 w-5 text-primary-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                  <p className="text-sm text-primary-700">{currentTool.topMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : <></>}
                          <input type="hidden" name="tool-name" value={currentTool.id} />
                          {formFields.map((formField, i) => (
                            <div className={formField.field.colSpan !== 1 ? "col-span-2" : ""} key={i}>
                              <Factory component={formField.field} value={formField.value} onChange={handleChange.bind(this, formField)} />
                            </div>
                          ))}
                          <div className="col-span-2 mb-6 space-y-8 flow-root">
                            {/* Submit buttom */}

                            <div className="flex gap-4">
                              {quantity !== -1 && <QuantityInput onChange={handleChange.bind(this, { field: { type: "n", name: "n" }, value: quantity })} value={quantity} maxQuantity={currentTool.maxQuantity} />}
                              <Button type="submit" className="h-12 grow select-none" disabled={props.isLoading} isLoading={isLoading}>
                                {currentTool.submitButtonLabel ? currentTool.submitButtonLabel : "Generate"}
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* END: Current Tool Form ------------------ */}
                    </div>
                  }
                  {outputList.length > 0 &&
                    <>
                      {currentTool.id === "rewrite-paragraph" &&
                        <ArticleRewriterPage
                            user={appDataContext.user}
                            output={outputList}
                            requestText={formFields.find(f => f.field.name === 'text').value}
                        />
                      }
                      {currentTool.id === "article-generator" &&
                        <EditContent
                            output={outputList}
                            setOutput={setOutputList}
                            formFields={formFields}
                            setFormFields={setFormFields}
                            {...props}
                        />
                      }
                      {
                      (
                          currentTool.id === "tldr" ||
                          currentTool.id === "website-headlines" ||
                          currentTool.id === "website-subheadlines" ||
                          currentTool.id === "product-descriptions" ||
                          currentTool.id === "rewrite-with-keyword" ||
                          currentTool.id === "grammar-rewriter" ||
                          currentTool.id === "sentence-rewriter" ||
                          currentTool.id === "meta-descriptions" ||
                          currentTool.id === "blog-outline" ||
                          currentTool.id === "essay-intros" ||
                          currentTool.id === "essay-outlines" ||
                          currentTool.id === "ecommerce-product-features" ||
                          currentTool.id === "email-subject-lines" ||
                          currentTool.id === "confirmation-email" ||
                          currentTool.id === "cancellation-email" ||
                          currentTool.id === "welcome-email" ||
                          currentTool.id === "follow-up-email" ||
                          currentTool.id === "cold-email" ||
                          currentTool.id === "newsletters" ||
                          currentTool.id === "video-script-section" ||
                          currentTool.id === "video-script-intros" ||
                          currentTool.id === "press-releases" ||
                          currentTool.id === "cta" ||
                          currentTool.id === "about-us" ||
                          currentTool.id === "how-it-works" ||
                          currentTool.id === "testimonials" ||
                          currentTool.id === "quest" ||
                          currentTool.id === "attention-interest-desire-action" ||
                          currentTool.id === "problem-agitate-solution" ||
                          currentTool.id === "blog-intros" ||
                          currentTool.id === "job-descriptions" ||
                          currentTool.id === "project-text-proposal" ||
                          currentTool.id === "stories" ||
                          currentTool.id === "review-responder" ||
                          currentTool.id === "vision-statement" ||
                          currentTool.id === "mission-statement" ||
                          currentTool.id === "startup-name-generator" ||
                          currentTool.id === "sentence-expander" ||
                          currentTool.id === "rewrite-with-keyword" ||
                          currentTool.id === "ecommerce-product-names" ||
                          currentTool.id === "ecommerce-product-names" ||
                          currentTool.id === "ecommerce-category-descriptions" ||
                          currentTool.id === "ecommerce-product-descriptions" ||
                          currentTool.id === "pros-cons" ||
                          currentTool.id === "talking-points" ||
                          currentTool.id === "blog-conclusions" ||
                          currentTool.id === "blog-titles-listicles" ||
                          currentTool.id === "blog-titles" ||
                          currentTool.id === "blog-sections" ||
                          currentTool.id === "paragraph-generator" ||
                          currentTool.id === "paragraph-generator" ||
                          currentTool.id === "faq-answers" ||
                          currentTool.id === "faq" ||
                          currentTool.id === "meta-descriptions-url" ||
                          currentTool.id === "meta-titles" ||
                          currentTool.id === "feature-to-benefit" ||
                          currentTool.id === "value-proposition" ||
                          currentTool.id === "quora-answers" ||
                          currentTool.id === "personal-bio" ||
                          currentTool.id === "linkedin-posts" ||
                          currentTool.id === "tweets" ||
                          currentTool.id === "twitter-threads" ||
                          currentTool.id === "youtube-tag-generator" ||
                          currentTool.id === "youtube-description" ||
                          currentTool.id === "youtube-titles" ||
                          currentTool.id === "google-descriptions" ||
                          currentTool.id === "google-headlines" ||
                          currentTool.id === "facebook-headlines" ||
                          currentTool.id === "facebook-primary" ||
                          currentTool.id === "instagram-captions" ||
                          currentTool.id === "social-media-post-personal" ||
                          currentTool.id === "social-media-post-business" ||
                          currentTool.id === "blog-ideas" ||
                          currentTool.id === "blog-outline" ||
                          currentTool.id === "instagram-hashtag-generator" ||
                          currentTool.id === "pain-agitate-solution" ||
                          currentTool.id === "image-generator" ||
                          currentTool.id === "blog-writer"
                      ) &&
                      <OutputList
                              eventHandlers={{ plagiarismHandler, saveHandler, copyTextHandler, copyImageHandler }}
                              user={appDataContext.user}
                              output={outputList}
                          />
                      }
                    </>
                  }
                </div>
              </>
            }
            {!singleColLayout &&
              <div className="h-full mx-auto w-full justify-center flex flex-wrap xl:flex-nowrap max-w-3xl gap-6 lg:max-w-7xl">
                {/* Tool form panel */}
                <section aria-labelledby="Tool-title" className="w-full xl:w-1/3">
                  <div className="bg-white shadow sm:rounded-lg dark:bg-slate-900">

                    {/* Current Tool Header (switch tool button) - For 2 column layout only ------------------ */}
                    <>
                      <Tippy theme={"copymatic"} content="Switch to a different tool">
                        <div className="p-4 z-0 rounded-t-md group bg-white border border-transparent hover:border-gray-200 dark:hover:border-gray-800 duration-500 focus:scale-[1.0] hover:scale-[1.0] hover:cursor-pointer text-left dark:bg-slate-900 hover:dark:bg-slate-800 dark:text-white" onClick={() => setPanelOpen(true)}>
                          <div className="flex justify-between items-center mb-2">
                            {/* Icon + Tool name */}
                            <div className="flex items-center">
                              <ToolIcon className="mr-3" size="md" category={currentTool.category} icon={currentTool.icon} customColor={currentTool.customIconColor} />
                              <h1 id="applicant-information-title" className="text-lg font-extrabold leading-6 text-gray-900 dark:text-white">
                                {currentTool.name}
                              </h1>
                            </div>
                            <div>
                              <ChevronUpDownIcon className="flex-shrink-0 h-5 w-5  fill-gray-500 group-hover:fill-gray-800 dark:group-hover:fill-gray-400 duration-200" aria-hidden="true" />
                            </div>
                          </div>
                          <p className="mt-1 max-w-2xl line-clamp-2 text-sm text-gray-400 font-medium" title={currentTool.description}>
                            {currentTool.description}
                          </p>
                        </div>
                      </Tippy>
                    </>
                    {/* /END: Tool switcher header -------------------------------------- */}
                    <>
                      <div className="border-t dark:border-slate-700 px-4 pt-5 mb-8 space-y-8 flow-root">
                        <form className="grid grid-cols-2 gap-x-4 gap-y-5" onSubmit={formSubmitHandler}>
                          {(currentTool.topMessage && !paidUserStatus) ? (
                            <div className="rounded-md col-span-2 bg-primary-50 p-4">
                              <div className="flex">
                                <div className="flex-shrink-0">
                                  <InformationCircleIcon className="h-5 w-5 text-primary-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                  <p className="text-sm text-primary-700">{currentTool.topMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : <></>}
                          <input type="hidden" name="tool-name" value={currentTool.id} />
                          {formFields.map((formField, i) => (
                            <div className={formField.field.colSpan !== 1 ? "col-span-2" : ""} key={i}>
                              <Factory component={formField.field} value={formField.value} onChange={handleChange.bind(this, formField)} />
                            </div>
                          ))}
                          <div className="col-span-2 mb-6 space-y-8 flow-root">
                            {/* Submit buttom */}

                            <div className="flex gap-4">
                              {quantity !== -1 && <QuantityInput onChange={handleChange.bind(this, { field: { type: "n", name: "n" }, value: quantity })} value={quantity} maxQuantity={currentTool.maxQuantity} />}
                              <Button type="submit" className="h-12 grow select-none" disabled={props.isLoading} isLoading={isLoading}>
                                {currentTool.submitButtonLabel ? currentTool.submitButtonLabel : "Generate"}
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  </div>

                  {/* Low quality results info link */}
                  {!singleColLayout && (
                    <div className="flex items-start p-4 pt-0 text-slate-500 opacity-75 justify-center font-medium text-sm">
                      <InformationCircleIcon className="h-5 w-5 mt-0 mr-2" />
                      <span>Getting low quality results? Increase the creativity or write a better description.</span>
                    </div>
                  )}

                  {/* Show explainer video link */}
                  {currentTool.hasVideo && currentTool.youtubeEmbedId && (
                    <>
                      <div onClick={openVideoModal} className="flex items-start p-4 pt-0 text-slate-400 opacity-100 justify-center font-medium text-base hover:text-slate-500 duration-300 shadow-none hover:cursor-pointer">
                        <QuestionMarkCircleIcon className="h-5 w-5 mt-0.5 mr-2" />
                        <span>Not sure how this works? Watch the explainer video!</span>
                        <div>{showVideoModal}</div>
                      </div>
                      {showVideoModal && <VideoModal onClose={() => setShowVideoModal(false)} youtubeEmbedId={currentTool.youtubeEmbedId} />}
                    </>
                  )}
                </section>

                {/* Output panel (If not single layout) */}
                {!singleColLayout && (
                  <div className="space-y-6 min-h-full w-full h-full xl:w-2/3">
                    <OutputPanel outputType={currentTool.outputType}
                      onWriteExample={writeExampleHandler}
                      subtitle={currentTool.outputPanelSubtitle || defaultOutputPanelSubtitle}
                      showExampleButton={currentTool.showExampleButton}
                      tool={currentTool}
                      isLoading={isLoading}
                      outputList={outputList}
                      eventHandlers={eventHandlers}
                      formFields={formFields}
                    />
                  </div>
                )}
              </div>
            }
          </main>
        </>
      )}
    </>
  );
}
